import styled from 'styled-components';

import pdfIconBackground from '../images/pdf-background.png';

export const Hero = styled.section`
  background-color: #04093d;
  min-height: 800px;
  position: relative;
`;

export const HeroBackground = styled.div`
  position: absolute;
  top: 40%;
  opacity: 0.05;
  left: 60px;
  height: 50%;
  width: 100%;
  background-image: url('${pdfIconBackground}');
  background-repeat: space;
  background-position: 60px 0;
`;
