import axios, { AxiosError } from 'axios';
import { toPairs } from 'lodash';
import { Dispatch } from 'redux';

import { Api } from '../../api/api';
import ActionTypes from '../actionTypes';
import { SdkAction, createSdkAction, onFulfill, onPending, onReject } from '../utils/actions';

export function closeFileModal(): SdkAction {
  return createSdkAction(ActionTypes.CLOSE_FILER_MODAL, {
    callback: (_: Api, dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.CLOSE_FILER_MODAL });
      return Promise.resolve(true);
    },
  });
}

export function saveTemporaryFile(file: File | null): SdkAction {
  return createSdkAction(ActionTypes.SAVE_TEMPORARY_FILE, {
    callback: (_: Api, dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.SAVE_TEMPORARY_FILE, payload: file });
      return Promise.resolve(true);
    },
  });
}

export function saveTemporaryCaseId(caseId: string): SdkAction {
  return createSdkAction(ActionTypes.SAVE_TEMPORARY_CASE, {
    callback: (_: Api, dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.SAVE_TEMPORARY_CASE, payload: caseId });
      return Promise.resolve(true);
    },
  });
}

export function getSignedUrl(
  fileName: string,
  uuid: string,
  userId: number | string | null,
  requiresAuthentication = true,
): SdkAction {
  return createSdkAction(ActionTypes.GET_SIGNED_URL, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.GET_SIGNED_URL) });

      return sdk
        .request('/sign-s3', {
          body: { file_name: `${userId}/${uuid}/${fileName}` },
          method: 'post',
          requiresAuthentication,
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.GET_SIGNED_URL),
            payload: res.data,
          });
          return Promise.resolve(res.data);
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.GET_SIGNED_URL) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function uploadDocument(url: string, body: Record<string, string>, file: File): SdkAction {
  return createSdkAction(ActionTypes.UPLOAD_DOCUMENT, {
    callback: (_: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.UPLOAD_DOCUMENT) });

      const fd = new FormData();
      const entries = toPairs(body);

      for (let i = 0; i < entries.length; i += 1) {
        const pair = entries[i];
        fd.append(pair[0], pair[1]);
      }

      fd.append('file', file, file.name);

      return axios(url, {
        method: 'post',
        data: fd,
      })
        .then((res) => {
          dispatch({ type: onFulfill(ActionTypes.UPLOAD_DOCUMENT) });
          return Promise.resolve(res);
        })
        .catch((res) => {
          dispatch({ type: onReject(ActionTypes.UPLOAD_DOCUMENT) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function createCitation(
  briefKey: string,
  caseId: string,
  requiresAuthentication = true,
  inputLinks?: Record<string, string[]>,
): SdkAction {
  return createSdkAction(ActionTypes.CITE_DOCUMENTS, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.CITE_DOCUMENTS) });

      return sdk
        .request('/process-documents', {
          body: { brief_s3_key: briefKey, case_id: caseId, input_links: inputLinks },
          method: 'post',
          requiresAuthentication,
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.CITE_DOCUMENTS),
            payload: res.data,
          });
          return Promise.resolve(res.data);
        })
        .catch((res: AxiosError) => {
          dispatch({ type: onReject(ActionTypes.CITE_DOCUMENTS) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function getTotalCases(): SdkAction {
  return createSdkAction(ActionTypes.GET_TOTAL_CASES, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.GET_TOTAL_CASES) });

      return sdk
        .request('/total-cases')
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.GET_TOTAL_CASES),
            payload: res.data,
          });
          return Promise.resolve();
        })
        .catch(() => {
          dispatch({ type: onReject(ActionTypes.GET_TOTAL_CASES) });
          return Promise.reject();
        });
    },
  });
}
