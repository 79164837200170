import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getTotalCases as getTotalCasesAction } from '../lib/actions/documents';
import { casesLoadingSelector, casesSelector } from '../selectors/documents';
import { H4 } from './Typography';

const Meta = styled.span`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #8c91bd;
`;

export function CaseCount(): JSX.Element {
  const dispatch = useDispatch();
  const totalCount = useSelector(casesSelector);
  const loading = useSelector(casesLoadingSelector);

  useEffect(() => {
    dispatch(getTotalCasesAction());
  }, []);

  return (
    <div className="flex flex-col items-center" style={{ marginTop: 40, marginBottom: 40 }}>
      {loading ? (
        <Meta>Loading case count...</Meta>
      ) : (
        <>
          <H4 color="white">{totalCount} Cases</H4>
          <Meta>Successfully processed and counting</Meta>
        </>
      )}
    </div>
  );
}
